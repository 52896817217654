<div class="container">
  <div class="warning">
    <div class="warn-container">
      <div class="warn-icon">
        <ui-svg-icon icon="warning-large"></ui-svg-icon>
      </div>
      <div class="info">
        <div>
          Heavy videos should not be used in hosted display ads because they
          will significantly increase the weight of your creatives, which will
          in turn lead to ads being blocked by
          <a
            href="https://developers.google.com/web/updates/2020/05/heavy-ad-interventions"
            target="_blank"
            >Google Chrome</a
          >
          or not approved by your chosen ad network.
        </div>
        <div class="info-2">
          Before uploading heavy videos, please read and agree to our
          <span class="info-terms">&nbsp;Terms and Conditions:</span>
        </div>
      </div>
    </div>
  </div>
  <terms-conditions-content class="terms-content"> </terms-conditions-content>
  <div class="buttons-container">
    <ui-button type="default" text="Cancel" (click)="cancel()"></ui-button>
    <ui-button
      type="primary"
      text="Agree and proceed"
      (click)="agreeAndproceed()"
    ></ui-button>
  </div>
</div>
