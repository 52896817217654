// script-loader.service.ts
import { ConfigService } from '../../config/config.service';
import { NewRelicOptions } from '@bannerflow/sentinel';

export class NewRelicConfig {
  public static init(): NewRelicOptions {
    const isProduction = ConfigService.isProduction();
    const enable = ConfigService.isProduction() || ConfigService.isSandbox();
    const allowedOriginsProduction = [
      'https://login.bannerflow.com',
      'https://api.bannerflow.com/video-upload',
      'https://app.bannerflow.com',
    ];

    const allowedOriginsSandbox = [
      'https://sandbox-login.bannerflow.com',
      'https://sandbox-api.bannerflow.com/video-upload',
      'https://sandbox-app.bannerflow.com',
    ];

    return {
      applicationId: isProduction ? '538568983' : '538568982',
      accountId: isProduction ? '4122654' : '4232543',
      agentId: isProduction ? '538528753' : '538568843',
      trustKey: '4122654',
      origins: isProduction ? allowedOriginsProduction : allowedOriginsSandbox,
      licenseKey: isProduction
        ? 'NRJS-bd28b6acdc31b77b97c'
        : 'NRJS-d0b27a9b958bc4b281c',
      applicationVersion: ConfigService.config?.RELEASE_NAME,
      releaseName: ConfigService.config?.RELEASE_NAME,
      releaseId: ConfigService.config?.RELEASE_NAME,
      enabled: enable,
    };
  }
}
