import { AppStage } from './app-stage.enum';
import { IConfig } from './config.type';

export const config: IConfig = {
  IDP_URL: 'https://sandbox-login.bannerflow.com',
  CLIENT_ROOT: 'https://sandbox-videoupload.bannerflow.com/',
  API_URL: 'https://sandbox-api.bannerflow.com/video-upload/',
  B2_URL: 'https://sandbox-app.bannerflow.com/',
  GTM_ID: 'GTM-W5CTW5Z',
  STAGE: AppStage.sandbox,
  RELEASE_NAME: '20240820.1_185527',
  CLIENT_ID: 'SxlFWQ9kjNJiYHg8ei3pIolxBhtvyyo5',
};
