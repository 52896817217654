import { Component } from '@angular/core';
import { AppComponentStore, VideoTypeEnum } from 'src/app/app.component.store';

@Component({
  selector: 'video-upload-container-component',
  templateUrl: './video-upload-container.component.html',
  styleUrls: ['./video-upload-container.component.scss'],
})
export class VideoUploadContainerComponent {
  constructor(private appComponentStore: AppComponentStore) {}

  public selectedChange(isChangingToDisplay: boolean): void {
    const videoType: VideoTypeEnum = isChangingToDisplay
      ? VideoTypeEnum.display
      : VideoTypeEnum.social;
    this.appComponentStore.setSelectedTab(videoType);
  }
}
