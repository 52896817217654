<div class="content">
  <p>By clicking below, you’re agreeing to the following.</p>

  The files uploaded in this tab may only be used for social ads or to export
  creatives. The URL(s) may not be used for display advertisements hosted by
  Bannerflow. Bannerflow will store metadata related to the uploaded video and
  monitor that the video is only used for social ads or exports. If you do not
  follow these terms, Bannerflow has the right to cease hosting of the video and
  your creative will not be served as a display ad.
</div>
