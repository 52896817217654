export class SizeUtility {
  /**
   * Convert bytes to based on different size unit KB,MB,GB
   */
  public static convertBytesTo(bytes: number, sizeUnit: SizeUnit): number {
    const marker = 1024;
    const kiloBytes: number = marker; // One Kilobyte is 1024 bytes
    const megaBytes: number = marker * marker; // One MB is 1024 KB
    const gigaBytes: number = marker * marker * marker; // One GB is 1024 MB

    switch (sizeUnit) {
      case SizeUnit.bytes:
        return bytes;
      case SizeUnit.KB:
        return bytes / kiloBytes;
      case SizeUnit.MB:
        return bytes / megaBytes;
      case SizeUnit.GB:
        return bytes / gigaBytes;

      default:
        break;
    }

    return 0;
  }

  public static roundToDecimals(decimals: number, someNumber: number): string {
    return someNumber.toFixed(decimals);
  }
}

export enum SizeUnit {
  bytes = 'bytes',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
}
