import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ConfigService } from './app/core/config/config.service';

const bootAngular: () => void = () => {
  platformBrowserDynamic([
    { provide: ConfigService, useClass: ConfigService },
  ]).bootstrapModule(AppModule);
};

if (ConfigService.isProduction()) {
  enableProdMode();
}

bootAngular();
