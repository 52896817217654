<main-layout>
  <ng-container ngProjectAs="center-region">
    <ui-tabs class="tabs-container">
      <ui-tab
        [headerId]="'interaction-videos-for-hosted-display-ads'"
        name="FILES FOR HOSTED DISPLAY ADS"
        (selectedChange)="selectedChange($event)"
      >
        <video-upload-content></video-upload-content>
      </ui-tab>
      <ui-tab
        [headerId]="'interaction-videos-for-social-ads'"
        name="FILES FOR SOCIAL ADS"
      >
        <video-upload-content></video-upload-content>
      </ui-tab>
    </ui-tabs>
  </ng-container>
</main-layout>
