<ng-container ngProjectAs="center-region" *ngIf="limitMb$ | async as limitMb">
  <div class="list-container">
    <p class="text">
      Here you can upload your files that you want to use in creative studio.
      Supported file types are: .{{ allowedFileExtentions.join(', .') }}.<br />
      <b
        >For MP4 we highly recommend you to upload these straight in to the
        brand library in creative studio,
        <a
          href="https://support.bannerflow.com/en/articles/6615116-working-with-videos"
          target="_blank"
          rel="noopener"
          >read more here</a
        >.</b
      ><br />
      Please note that hosting of file will be charged to your contract. If it’s
      not in your contract please contact your customer success manager.<br />
      Max allowed file size is <span>{{ limitMb }}</span> MB. Files that exceed
      this limit will be Skipped. For video
      <a
        href="https://support.bannerflow.com/en/articles/2491006-compressing-and-optimising-video-for-html5-creatives"
        target="_blank"
        rel="noopener"
        >click here</a
      >
      to read how to compress your file before uploading it.
    </p>

    <div class="upload-header">
      <label for="file-upload">
        <input
          #fileInput
          id="file-upload"
          name="file"
          class="file-input"
          type="file"
          multiple="true"
          [accept]="'.' + allowedFileExtentions.join(',.')"
          (change)="addFiles($event.target.files, limitMb)"
        />

        <input type="hidden" name="fileHidden" />

        <ui-button type="primary" text="Add Files" icon="plus-skinny-minimal">
        </ui-button>
      </label>
    </div>

    <div class="video-list">
      <div
        class="drag-drop-video hide"
        id="drag-drop-video"
        dragDrop
        (filesDropped)="addFiles($event, limitMb)"
      >
        <div class="drag-drop-video-overlay">
          <div class="drag-drop-video-hover">
            <ui-svg-icon
              set="misc"
              icon="undefinedother-sizes-arrow-upload"
            ></ui-svg-icon>
            Drop to upload
          </div>
        </div>
        <div class="drag-drop-video-underlay"></div>
      </div>
      <video-list></video-list>
    </div>

    <div class="copy-container">
      <h4>Get the URLs ({{ (getUploadedUrls() | async).length }})</h4>
      <p class="text">
        We do not store file URLs, so make sure to copy them to clipboard or
        download them as a .txt before you close or reload this page
      </p>
      <div class="buttons">
        <ui-button
          class="button"
          [disabled]="!(getUploadedUrls() | async).length"
          type="default"
          svgIcon="copy"
          text="copy to clipboard"
          (click)="copyUploadedfilesToClipBoard()"
        >
        </ui-button>
        <ui-button
          class="button"
          [disabled]="!(getUploadedUrls() | async).length"
          type="default"
          svgIcon="download"
          text="download as .txt"
          (click)="downloadUploadedfilesAsTxt()"
        >
        </ui-button>
      </div>
    </div>
  </div>
</ng-container>
